import { useMemo } from 'react';

import { interpolate } from '@utils';
import { useKinndomContext } from '@contexts/KinndomContext';

import NavbarSlot from '@components/navbar/NavbarSlot';

const NAVBAR_EMOJI = process.env.NODE_ENV === 'production' ? '💠' : '🌿';

// TODO: Extract out into separate component
const NavbarQuickLinks = (props) => {
  const {
    unreadNotificationsCount,
  } = props;

  const hasUnreadNotifications = unreadNotificationsCount > 0;

  const items = [
    {
      label: {
        icon: {
          name: hasUnreadNotifications ? 'bell-exclamation' : 'bell',
          color: hasUnreadNotifications ? '#FFCB00' : '#FFFFFF',
        },
        text: hasUnreadNotifications ? unreadNotificationsCount : null,
      },
      path: '/notifications',
      tooltip: 'Notifications',
    },
  ];

  return items.map((item, index) => (
    <NavbarSlot
      key={index}
      type="link"
      label={item.label}
      url={item.url}
      path={item.path}
      openInNewWindow={item.openInNewWindow}
      tooltip={item.tooltip}
    />
  ));
};

// TODO: Extract out into separate component
const UserNavbarMenu = (props) => {
  const {
    userName,
    userAvatarUrl,
    latestChangelogVisited,
  } = props;

  const label = {
    text: userName || 'User',
    imageUrl: userAvatarUrl,
  };

  const flags = useMemo(() => {
    const flags = [];

    if (!latestChangelogVisited) {
      flags.push({
        icon: 'circle-small',
        color: '#CF2828',
      });
    }

    return flags;
  }, [latestChangelogVisited]);

  const items = [
    {
      type: 'link',
      label: {
        text: 'Profile',
        icon: 'user',
      },
      path: '/profile',
    },
    {
      type: 'link',
      label: {
        text: 'Log Out',
        icon: 'right-from-bracket',
      },
      path: '/logout',
    },
    {
      type: 'divider',
    },
    {
      type: 'link',
      label: {
        text: window.KINN_APP_VERSION,
      },
      path: '/changelog',
      flags: flags,
    },
  ];

  return (
    <NavbarSlot
      type="menu"
      label={label}
      items={items}
      isAlignedRight={true}
      menuClassName="min-w-32"
    />
  );
};

export default function Navbar(_props) {
  const kinndom = useKinndomContext();

  const {
    kinndomKey,
    defaultWebsiteId,
    defaultWebsiteBaseUrl,
    isAuthenticated,

    currentUserName,
    isCurrentUserAdmin,
    currentUserHomepageOverride,
    currentUserAvatarUrl,
    currentUserLatestChangelogVisited,
    currentUserUnreadNotificationsCount,
  } = window.appContext;

  // TODO: Refactor
  const interpolationData = useMemo(() => ({
    kinndomKey: kinndomKey,
    defaultWebsiteId: defaultWebsiteId,
    defaultWebsiteBaseUrl: defaultWebsiteBaseUrl,

    kinndom: kinndom,
    config: kinndom.configuration,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [kinndom]);

  const navbarMenus = useMemo(() => {
    return window.NAVBAR_MENUS
      .filter((menu) => (
        !menu.isHidden
        && (!menu.isAdminOnly || isCurrentUserAdmin)
      )).map((menu) => interpolate(menu, interpolationData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interpolationData]);

  const DEFAULT_HOMEPAGE_URL = '/';
  const homepageUrl = currentUserHomepageOverride || DEFAULT_HOMEPAGE_URL;

  /**************************************************************************************************/

  if (!isAuthenticated) return null;

  return (
    <nav className="hidden md:flex overflow-y-unset fixed inset-x-0 top-0 z-30 max-h-navbar-height bg-[var(--navbar-background-color)] px-8 text-[var(--navbar-foreground-color)] shadow-lg">
      <div className="container mx-auto flex max-w-(--breakpoint-2xl) px-4 flex-row">
        <div className="flex w-full justify-between flex-row">
          <div className="flex flex-row">
            <NavbarSlot
              type="link"
              label={{
                text: NAVBAR_EMOJI,
              }}
              url={homepageUrl}
            />

            {navbarMenus.map((menu, index) => (
              <NavbarSlot
                key={index}
                type="menu"
                {...menu}
              />
            ))}
          </div>

          <div className="flex flex-col flex-row">
            <NavbarQuickLinks
              unreadNotificationsCount={currentUserUnreadNotificationsCount}
            />
            <UserNavbarMenu
              userName={currentUserName}
              userAvatarUrl={currentUserAvatarUrl}
              latestChangelogVisited={currentUserLatestChangelogVisited}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
