/***************************************************************************************************
** getIconProps
***************************************************************************************************/

import cn from '@utils/cn';

export default function getIconProps(icon, otherProps = {}) {
  if (!icon) return null;

  if (icon === 'resource://null') {
    return null;
  } else if (typeof icon === 'string' || Array.isArray(icon)) {
    return {
      iconName: icon,
      ...otherProps,
    };
  } else {
    const { name, ...iconProps } = icon;

    const className = cn(iconProps.className, otherProps.className);

    return {
      iconName: name,
      ...iconProps,
      ...otherProps,
      className: className,
    };
  }
}
