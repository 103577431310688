import ReactDOMClient from 'react-dom/client';

import { deepFreezeObject, booleanify, getAuthenticityToken } from '@utils';

import App from '~/app/frontend/javascripts/App.js';

document.addEventListener('DOMContentLoaded', () => {
  window.appContext = deepFreezeObject(booleanify({
    ...document.body.dataset,
    authenticityToken: getAuthenticityToken(),
  }));

  const consolePrintKinnMark = () => console.info(window.KINN_MARK);
  window.consolePrintKinnMark = consolePrintKinnMark;

  /**************************************************************************************************/

  const appElement = document.getElementById('app');
  if (appElement === null) throw new Error('Missing app element');

  const app = ReactDOMClient.createRoot(appElement, {
    // onUncaughtError: (error, errorInfo) => {},
    // onCaughtError: (error, errorInfo) => {}
  });

  const props = JSON.parse(appElement.dataset.props);
  const component = appElement.dataset.component;

  app.render(
    <App component={component} {...props} />
  );
});
